import React, { FC } from 'react'
import styles from './Home.module.scss'
import { GridItem } from 'modules/Layout/components/GridItem'
import { FlexColumn } from 'components/FlexColumn'
import { Orders } from './Orders'
import { Insights } from './Insights'
import { Devices } from './Devices'
import { useConxSdk } from 'modules/ConxSdkProvider'

export const Home: FC = () => {
  const { region } = useConxSdk()
  const isOrdersEnabled = process.env.NEXT_PUBLIC_ECOMM_ENABLED_REGIONS?.includes(region)

  return (
    <GridItem verticalAlign='top'>
      <div className={styles.Home}>
        <FlexColumn spacing={{ default: 'extraExtraLoose' }}>
          <Devices />
          <Insights />
          {isOrdersEnabled && <Orders />}
        </FlexColumn>
      </div>
    </GridItem>
  )
}
